import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/next@15.2.0_@babel+core@7.24.5_@playwright+test@1.50.1_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.0_@babel+core@7.24.5_@playwright+test@1.50.1_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/add-places.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/categories.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/italy-map.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/new-york-map.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/one-vanderbilt.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/thailand-map.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["WelcomePageCategorySelect"] */ "/vercel/path0/src/app/[lang]/welcome-page-category-select.tsx");
